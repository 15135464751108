<template>
  <div class="join">
    <div class="login_bg">
      <img src="@/assets/images/login_bg.jpg">
    </div>
    <div class="baseBox">
      <div class="joinBox errorpage">
        <h1><i class="fas fa-exclamation-triangle"></i>Error 503</h1>
        <h3>
          죄송합니다. 현재 시스템 응답이 원활하지 않습니다.<br />오류가
          지속적으로 발생할 경우 관리자에서 문의해주시기 바랍니다.
        </h3>
        <div class="buttonWrap">
          <router-link :to="getUrl()">
            <button class="large point">홈으로 이동</button>
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer";

export default {
  components: { Footer },
  methods : {
    getUrl(){
      var accountId = this.$route.params.accountId;
      // var userType = localStorage.getItem("userType");
      var result = "";
      if(this.$store.state.store.isAdmin == true) {
        result = `/${accountId}/dashboard/overallAdmin`;
      } else{
        result = `/${accountId}/eventControl/liveListControlUser`;
      }
      return result
    }
  }
};
</script>
